/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');

/* loading2 https://loading.io/css */
.lds2 {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}

.lds2 div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds2 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent;
}

.lds2 div:nth-child(1) { animation-delay: -0.45s; }
.lds2 div:nth-child(2) { animation-delay: -0.3s; }
.lds2 div:nth-child(3) { animation-delay: -0.15s; }

@keyframes lds2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* global */
body {
  font-family: Sora, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #fafafa;


  /* react-toastify */
/* stylelint-disable */
  --toastify-color-light: #fff;
  --toastify-color-dark: #3A3740;
  --toastify-color-info: #1A68FF;
  --toastify-color-success: #25A84C;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #D42A2A;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 360px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: Sora,sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

 
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;



  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
 
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);

}

.Toastify__toast {
  border-radius: 2px !important;
}

@media (max-width: 480px) {
  .Toastify__toast {
    top: -4.5em !important;
  }
}
/* stylelint-enable */


